<template>
    <div>
        <!--         <span v-show="false">{{create_liveCandle}}</span> -->
        <div class="top-header blackBG border-bottom">
            <div class="marquee border-top-0 border-bottom">
                <banner-slider></banner-slider>
            </div>
        </div>
        <section class="blockElement bg-white innerPage-banner">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="breadCrum pb-3">
                            <h2>Markets</h2>
                            <nav aria-label="breadcrumb mt-2">
                                <ol class="breadcrumb mb-0">
                                    <li class="breadcrumb-item">
                                        <router-link class="green" to="/">Home</router-link>
                                    </li>
                                    <li class="breadcrumb-item active" aria-current="page">
                                        Market
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement marketSingleBlock bg-white space pt-0">
            <div class="container">
                <div class="row mob-scroll-content nowrap">
                    <div class="col-3 mb-4 mb-xl-0" v-for="(spreads, index) in overAllGainer" :key="index">
                        <div class="card-body">
                            <div class="d-flex align-items-center justify-content-between mb-4 pb-3">
                                <p class="medium mb-0">
                                    {{ index > 1 ? "Top Looser (24H)" : "Top Gainer (24H)" }}
                                </p>
                                <p class="mb-0 d-flex align-items-center" :class="{
                                  red: parseFloat(spreads.CS) < 0,
                                  green: parseFloat(spreads.CS) > 0,
                                }">
                                    {{ COMMON.formatPrice(spreads.CS) }}%<vue-feather class="ms-1" size="16" :type="
                                      parseFloat(spreads.CS) > 0
                                        ? 'corner-right-up'
                                        : 'corner-right-down'
                                    "></vue-feather>
                                </p>
                            </div>
                            <div class="d-flex align-items-center justify-content-between">
                                <span class="d-flex align-items-center">
                                    <!-- <img width="38" src="assets/images/EURUSD.png" alt="Chart" title="USDCLP"> -->
                                    <div class="dualIcon">
                                        <a class="d-inline-flex mx-2" href="#"
                                            v-if="spliting(spreads.other.isinid, 0)"><img height="22" width="22" :src="
                                              'assets/images/crypto/' +
                                              spliting(spreads.other.isinid, 0) +
                                              '.webp'
                                            " alt="" title="" /></a>
                                        <a class="d-inline-flex mx-2" href="#"
                                            v-if="spliting(spreads.other.isinid, 1)"><img width="22" height="22" :src="
                                              'assets/images/crypto/' +
                                              spliting(spreads.other.isinid, 1) +
                                              '.webp'
                                            " alt="" title="" /></a>
                                        <span class="singleName" v-else>{{
                                        spreads.other.isinid.slice(0, 1)
                                        }}</span>
                                    </div>
                                    <span class="medium ps-2 f-16">{{ spreads.other.isinid
                                    }}<span class="d-block" :class="{
                                          red: spreads.buy_increase_staus == 0,
                                          green: spreads.buy_increase_staus == 1,
                                        }">{{ COMMON.formatPrice(spreads.B, true) }}</span></span>
                                </span>
                                <img src="assets/images/chart/greenGraph.svg" alt="Chart" title="Top Gainer Chart" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement marketSingleBlock bg-white space-footer">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex align-items-center justify-content-between border-bottom trade pb-2">
                            <ul class="toplinks visible d-flex align-items-center mb-0">
                                <li class="ps-0" @click="changeTab(1)">
                                    <a href="javascript:void(0)" :class="[{ active: tab == 1 }]">Major</a>
                                </li>
                                <li  @click="changeTab(8)">
                                    <a href="javascript:void(0)" :class="[{ active: tab == 8 }]">Minor</a>
                                </li>
                                <li  @click="changeTab(9)">
                                    <a href="javascript:void(0)" :class="[{ active: tab == 9 }]">Exotic</a>
                                </li>
                                <!-- <li @click="changeTab(8)">
                                    <a href="javascript:void(0)" :class="[{ active: tab == 8 }]">Cross Pairs</a>
                                </li> -->
                                <li @click="changeTab(2)">
                                    <a href="javascript:void(0)" :class="[{ active: tab == 2 }]">Crypto</a>
                                </li>
                                <li @click="changeTab(3)">
                                    <a href="javascript:void(0)" :class="[{ active: tab == 3 }]">Commodities</a>
                                </li>
                                <!-- <li @click="changeTab(4)">
                  <a href="javascript:void(0)" :class="[{ active: tab == 4 }]"
                    >Futures</a
                  >
                </li> -->
                                <!-- <li @click="changeTab(5)">
                                    <a href="javascript:void(0)" :class="[{ active: tab == 5 }]">Stocks</a>
                                </li> -->
                                <li @click="changeTab(6)">
                                    <a href="javascript:void(0)" :class="[{ active: tab == 6 }]">Indices</a>
                                </li>
                                <!-- <li @click="changeTab(7)">
                  <a href="javascript:void(0)" :class="[{ active: tab == 7 }]"
                    >Bonds</a
                  >
                </li> -->
                            </ul>
                            <div class="d-flex">
                                <a href="javascript:void(0)" data-bs-toggle="modal"
                                    @click="performanceCharttype = 'D';drawPerformanceChart()" data-bs-target="#rankcurrencies"
                                    class="market-details-btn me-2" v-if="Object.keys(this.currencies_list).length && Object.keys(get_currencies).length"><img src="assets/images/market-cur-icon.svg" /></a>

                                <form class="searchBar">
                                    <div class="form-group position-relative inputIcon mb-0 expandMobile"
                                        :class="opensearch ? 'active' : ''">
                                        <input type="text" class="form-control" placeholder="Search.." name=""
                                            v-model="search" @keyup="
                                              page = 1;
                                              limit = 10;
                                            " />
                                        <a @click.prevent="opensearch = !opensearch" class="searchButton" href="#">
                                            <vue-feather type="search"></vue-feather>
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table tableStriped markettable dataTable fixed-layout">
                                <thead>
                                    <tr class="border-bottom trade">
                                        <th width="330" class="ps-5 none">Pair</th>
                                        <th @click="setSorting('Last')">
                                            <span :class="{
                                              decending: sorting['Last'] == 1,
                                              ascending: sorting['Last'] == 0,
                                            }">Last</span>
                                        </th>
                                        <th @click="setSorting('Open')">
                                            <span :class="{
                                              decending: sorting['Open'] == 1,
                                              ascending: sorting['Open'] == 0,
                                            }">Open</span>
                                        </th>
                                        <th @click="setSorting('High')">
                                            <span :class="{
                                              decending: sorting['High'] == 1,
                                              ascending: sorting['High'] == 0,
                                            }">High</span>
                                        </th>
                                        <th @click="setSorting('Low')">
                                            <span :class="{
                                              decending: sorting['Low'] == 1,
                                              ascending: sorting['Low'] == 0,
                                            }">Low</span>
                                        </th>
                                        <th @click="setSorting('Chg')">
                                            <span :class="{
                                              decending: sorting['Chg'] == 1,
                                              ascending: sorting['Chg'] == 0,
                                            }">Chg.</span>
                                        </th>
                                        <th @click="setSorting('ChgP')">
                                            <span :class="{
                                              decending: sorting['ChgP'] == 1,
                                              ascending: sorting['ChgP'] == 0,
                                            }">Chg.%</span>
                                        </th>
                                        <!-- <th><span>Time</span></th> -->
                                        <!-- <th class="none">&nbsp;</th> -->
                                    </tr>
                                </thead>
                                <tbody v-if="!store.loading && Object.keys(currencies_list).length && Object.keys(get_currencies).length">
                                    <tr v-for="(spreads, index) in get_currencies.list" :key="spreads.i" :index="index">
                                        <td width="330">
                                            <span class="d-inline-flex align-items-center">
                                                <span class="pe-4 pe-lg-5" :class="[
                                                  {
                                                    red: parseFloat(spreads.CB) < 0,
                                                    green: parseFloat(spreads.CB) > 0,
                                                  },
                                                ]"><i class="fa" :class="[
                                                    {
                                                      'fa-caret-down': parseFloat(spreads.CB) < 0,
                                                      'fa-caret-up': parseFloat(spreads.CB || 0) > 0,
                                                    },
                                                  ]" aria-hidden="true"></i></span>
                                                <span class="pe-5 d-flex align-items-center">
                                                    <div class="dualIcon">
                                                        <a class="d-inline-flex mx-2" href="#"
                                                            v-if="spliting(spreads.other.isinid, 0)"><img height="22" width="22"
                                                                :src="
                                                                  'assets/images/crypto/' +
                                                                  spliting(spreads.other.isinid, 0) +
                                                                  '.webp'
                                                                " alt="" title="" /></a>
                                                        <a class="d-inline-flex mx-2" href="#"
                                                            v-if="spliting(spreads.other.isinid, 1)"><img height="22" width="22"
                                                                :src="
                                                                  'assets/images/crypto/' +
                                                                  spliting(spreads.other.isinid, 1) +
                                                                  '.webp'
                                                                " alt="" title="" /></a>
                                                        <span class="singleName" v-else>{{
                                                        spreads.other.isinid.slice(0, 1)
                                                        }}</span>
                                                    </div>
                                                    <span class="medium ps-2">{{
                                                    spreads.other.isinid
                                                    }}</span>
                                                </span>
                                                <img width="56" src="assets/images/chart/greenGraph.svg" alt="Chart"
                                                    title="Top Gainer Chart" />
                                            </span>
                                        </td>
                                        <td :class="{
                                          red: spreads.buy_increase_staus == 0,
                                          green: spreads.buy_increase_staus == 1,
                                        }">
                                            {{ COMMON.formatPrice(spreads.B, true) }}
                                        </td>
                                        <td>{{ parseFloat(spreads.O).toFixed(4) }}</td>
                                        <td>{{ parseFloat(spreads.H).toFixed(4) }}</td>
                                        <td>{{ parseFloat(spreads.L).toFixed(4) }}</td>

                                        <td :class="{
                                          red: parseFloat(spreads.CS) < 0,
                                          green: parseFloat(spreads.CS) > 0,
                                        }">
                                            {{
                                            parseFloat(
                                            parseFloat(spreads.B) - spreads.other.closepx
                                            ).toFixed(4)
                                            }}
                                        </td>
                                        <td :class="{
                                          red: parseFloat(spreads.CS) < 0,
                                          green: parseFloat(spreads.CS) > 0,
                                        }">
                                            {{ COMMON.formatPrice(spreads.CS) }}%
                                        </td>
                                        <!-- <td>{{COMMON.formatTime(spreads.T)}}</td> -->
                                       <!--  <td class="green">
                                            <vue-feather class="d-flex" size="18" type="clock"></vue-feather>
                                        </td> -->
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="7" class="text-center">
                                        <div class="text-center loadingIcon">
                                            <img src="assets/images/loader.gif" alt="Icon" class="norecord_img">
                                            <p>Loading please wait..</p>
                                        </div>
                                    </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-pagination d-flex align-items-center justify-content-end p-3 border-top"
                            v-if="get_currencies.total > 0">
                            <p class="pageNumber themecolor mb-0 semibold f-14 me-3 flex-shrink-0">
                                Page<span class="mx-1">{{ page }} of</span>{{ Math.ceil(get_currencies.total / limit) }}
                            </p>
                            <select class="form-select border-0" v-model="limit" @change="page = 1">
                                <option value="10">10 rows per page</option>
                                <option value="20">20 rows per page</option>
                                <option value="50">50 rows per page</option>
                                <option value="100">100 rows per page</option>
                            </select>
                            <div class="arialButton d-flex align-items-center ms-3 flex-shrink-0">
                                <a href="javascript:void(0)" class="angle me-2"
                                    v-if="Math.ceil(get_currencies.total / limit) > 1 && page > 1"
                                    @click="page = parseInt(page) - 1">
                                    <vue-feather type="chevron-left"></vue-feather>
                                </a>
                                <a href="javascript:void(0)" class="angle me-2 disabled" v-else>
                                    <vue-feather type="chevron-left"></vue-feather>
                                </a>
                                <a href="javascript:void(0)" class="angle"
                                    v-if="Math.ceil(get_currencies.total / limit) > page"
                                    @click="page = parseInt(page) + 1">
                                    <vue-feather type="chevron-right"></vue-feather>
                                </a>
                                <a href="javascript:void(0)" class="angle disabled" v-else>
                                    <vue-feather type="chevron-right"></vue-feather>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <div class="modal fade" id="rankcurrencies"  tabindex="-1" aria-labelledby="staticBackdropLabel">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="d-flex align-items-center justify-content-between w-100">
                        <h5 class="mb-0 modal-title f-16">
                            Ranked Returns( {{performanceTypeJson[performanceCharttype]}} %):
                            <span class="green">{{
                            tab == 1
                            ? "Major"
                            : tab == 2
                            ? "Cryptos"
                            : tab == 3
                            ? "Commodities"
                            : tab == 5
                            ? "Stocks"
                            : tab == 8
                            ? "Minor"
                            : tab == 9
                            ?  "Exotic"
                            :"Indices"
                            }}</span>
                        </h5>
                        <div class="d-flex align-items-center">
                            <!-- <div class="d-flex align-items-center position-relative ms-4">
                <a
                  href="javascript:void(0)"
                  class="megaDrop border-btn d-flex align-items-center justify-content-between d-md-none ms-2"
                  >D<i class="ms-3 fa fa-sort-desc" aria-hidden="true"></i
                ></a>
                <ul
                  class="hoverActive dropdown_menu_animated onMbile chartTimeline d-flex align-items-center justify-content-end mb-0"
                  :class="dropdownMenu ? 'show' : ''"
                >
                  <li>
                    <a
                      class="py-0"
                      :class="{
                        active: performanceChartFilter.currency == 'Major',
                      }"
                      href="javascript:void(0)"
                      @click="() => changeCurrencyType('Major')"
                      >Major</a
                    >
                  </li>
                  <li>
                    <a
                      class="py-0"
                      :class="{
                        active: performanceChartFilter.currency == 'Minor',
                      }"
                      href="javascript:void(0)"
                      @click="() => changeCurrencyType('Minor')"
                      >Cross</a
                    >
                  </li>
                  <li>
                    <a
                      class="py-0"
                      :class="{
                        active: performanceChartFilter.currency == 'All',
                      }"
                      href="javascript:void(0)"
                      @click="() => changeCurrencyType('All')"
                      >All</a
                    >
                  </li>
                </ul>
              </div> -->
                        </div>
                        <div class="d-flex align-items-center">
                            <div class="d-flex align-items-center position-relative me-2">
                                <a href="javascript:void(0)"
                                    class="megaDrop border-btn d-flex align-items-center justify-content-between d-md-none ms-2">D<i
                                        class="ms-3 fa fa-sort-desc" aria-hidden="true"></i></a>
                                <ul class="hoverActive dropdown_menu_animated onMbile chartTimeline d-flex align-items-center justify-content-end mb-0"
                                    :class="dropdownMenu ? 'show' : ''">
                                    <li :class="[{'active' : performanceCharttype == 'D'}]"><a class="py-0" href="javascript:void(0)" @click="performanceCharttype = 'D';drawPerformanceChart()">D</a></li>
                                    <li :class="[{'active' : performanceCharttype == 'W'}]"><a class="py-0" href="javascript:void(0)" @click="performanceCharttype = 'W';drawPerformanceChart()">W</a></li>
                                    <li :class="[{'active' : performanceCharttype == 'M'}]"><a class="py-0" href="javascript:void(0)" @click="performanceCharttype = 'M';drawPerformanceChart()">M</a></li>
                                    <li :class="[{'active' : performanceCharttype == 'Y'}]"><a class="py-0" href="javascript:void(0)" @click="performanceCharttype = 'Y';drawPerformanceChart()">Y</a></li>
                                </ul>
                            </div>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    </div>
                </div>

                <div class="modal-body position-relative">
                    <!-- <img
            src="assets/images/chart/Currency-performance-chart.jpg"
            alt="Icon"
            title="Risk & Performance"
          /> -->
                    <div id="performance-chart"></div>
                </div>
                <div class="modal-footer">
                    <div Class="d-flex align-items-center inlineRange mx-auto">
                        <p class="mb-0 f-12">-3.12%</p>
                        <Slider showTooltip="drag" v-model="performanceChartFilter.range.value"
                            :min="performanceChartFilter.range.min" :max="performanceChartFilter.range.max"
                            @change="(value) => drawPerformanceChart(value)" :step="-1" />
                        <p class="mb-0 f-12">+3.12%</p>
                        <!-- <a href="javascript:void(0)" class="share-icon">
                            <vue-feather size="15px" type="share-2"></vue-feather>
                        </a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Const } from "@/plugins/vue-provider-cache/const.mod";
import bannerslider from "../../components/shared/banner_slider";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import Slider from "@vueform/slider";
import { useMainStore } from "@/store";

export default {
    setup() {
        const store = useMainStore();
        return { store };
    },
    data() {
        return {
            opensearch: false,
            tab: 1,
            dropdownMenu: false,
            priceType: {
                1: "getMajorAll",
                2: "getCryptosAll",
                3: "getCommodityAll",
                4: "getFutureAll",
                5: "getStocksAll",
                6: "getIndicesAll",
                7: "getBondsAll",
                8: "getMinorAll",
                9: "getExoticAll",
            },
            loadChart: "",
            page: 1,
            performanceChartFilter: {
                currency: "Major",
                chart: "D",
                range: {
                    min: 0,
                    max: 100,
                    value: [0, 100],
                },
            },
            limit: 10,
            search: "",
            sorting: {
                Last: -1,
                Open: -1,
                High: -1,
                Low: -1,
                Chg: -1,
                ChgP: -1,
            },
            performanceCharttype : "D",
            performanceTypeJson : {
                'D' : 'Daily',
                'W' : 'Weekly',
                'M' : 'Monthly',
                'Y' : 'Yearly',

            }
        };
    },
    components: {
        bannerSlider: bannerslider,
        Slider,
    },
    methods: {
        changeCurrencyType(type) {
            this.performanceChartFilter.currency = type;
            this.performanceCharttype = 'D'
            this.drawPerformanceChart();
        },
        changeTab(value) {
            this.page = 1;
            this.limit = 10;
            this.search = "";
            if (value == 1) {
                this.performanceChartFilter.currency = "Major";
            } else if (value == 8) {
                this.performanceChartFilter.currency = "Minor";
            }
            this.tab = value;
            this.sorting = {
                Last: -1,
                Open: -1,
                High: -1,
                Low: -1,
                Chg: -1,
                ChgP: -1,
            };
            this.performanceCharttype = 'D'
        },
        setSorting(key) {
            try {
                for (let k in this.sorting) {
                    if (k == key) {
                        if (this.sorting[key] == 1) {
                            this.sorting = Object.assign(this.sorting, {
                                ...this.sorting,
                                [key]: -1,
                            });
                        } else {
                            this.sorting = Object.assign(this.sorting, {
                                ...this.sorting,
                                [key]: this.sorting[key] + 1,
                            });
                        }
                    } else {
                        this.sorting = Object.assign(this.sorting, {
                            ...this.sorting,
                            [k]: -1,
                        });
                    }
                }
            } catch (e) {
                console.log(e);
            }
        },
        spliting(val, index) {
            if (val) {
                if (val.includes("-")) {
                    return val.split("-")[index].toLowerCase();
                } else if (val.length >= 6) {
                    if (index) {
                        return val.slice(3, 7).toLowerCase();
                    } else {
                        return val.slice(0, 3).toLowerCase();
                    }
                }
            } else {
                return "";
            }
        },
        drawPerformanceChart(rangeValue) {
            let id = "performance-chart";
            let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === id);
            d?.dispose();
            var root = am5.Root.new(id);
            root.setThemes([am5themes_Animated.new(root)]);

            var chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    panX: true,
                    panY: true,
                    wheelX: false,
                    wheelY: false,
                    pinchZoomX: false,
                })
            );

            var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
            cursor.lineY.set("visible", false);

            var yRenderer = am5xy.AxisRendererY.new(root, {
                minGridDistance: 3,
                maxDeviation: 1,
            });
            yRenderer.labels.template.setAll({
                // centerY: am5.p50,
                // centerX: am5.p100,
                // paddingRight: 15,
                fontSize: 10,
            });

            var yAxis = chart.yAxes.push(
                am5xy.CategoryAxis.new(root, {
                    categoryField: "currency",
                    renderer: yRenderer,
                    tooltip: am5.Tooltip.new(root, {}),
                })
            );

            var xAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    renderer: am5xy.AxisRendererX.new(root, {}),
                })
            );
            

            // Create series
            // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
            var series = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    name: "Series 1",
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueXField: "value",
                    sequencedInterpolation: true,
                    categoryYField: "currency",
                    tooltip: am5.Tooltip.new(root, {
                        labelText: "{valueX}%",
                    }),
                })
            );

            // series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5 });
            // series.columns.template.adapters.add("fill", function (fill, target) {
            //   return chart.get("colors").getIndex(series.columns.indexOf(target));
            // });

            // series.columns.template.adapters.add("stroke", function (stroke, target) {
            //   return chart.get("colors").getIndex(series.columns.indexOf(target));
            // });

            // series.bullets.push(function (root, series, dataItem) {
            //   console.log(dataItem);
            //   return am5.Bullet.new(root, {
            //     // locationX: dataItem.dataContext.value ? 1 : -1,
            //     // locationY: 0.5,
            //     sprite: am5.Label.new(root, {
            //       centerY: am5.p50,
            //       // centerX: am5.p100,
            //       text: "{categoryY}",
            //       fontSize: 10,
            //       populateText: true,
            //     }),
            //   });
            // });

            // Set data
            var data = [];
            if(this.performanceCharttype == 'D'){
                Object.values(this.currencies_list).map((i) => {
                    let obj = {
                        value: parseFloat(parseFloat(i.CB).toFixed(2)),
                        currency: i.other.isinid,
                    };
                    data.push(obj);
                });
            }else if(this.performanceCharttype == 'W'){
                if(Object.keys(this.store.trendingIsins).length > 0 && Object.keys(this.currencies_list).length){
                    Object.keys(this.currencies_list).forEach(val => {
                        if(val in this.store.trendingIsins && Object.keys(this.store.trendingIsins[val]).length && this.store.trendingIsins[val].weekly && typeof JSON.parse(this.store.trendingIsins[val].weekly) == 'object' &&  JSON.parse(this.store.trendingIsins[val].weekly).open){
                            let obj = {
                                value: parseFloat(parseFloat(parseFloat((parseFloat(this.currencies_list[val].B) - parseFloat(JSON.parse(this.store.trendingIsins[val].weekly).open))/parseFloat(JSON.parse(this.store.trendingIsins[val].weekly).open))*100).toFixed(2)),
                                currency: this.currencies_list[val].other.isinid,
                            }
                            data.push(obj);
                        }

                    })

                }
            }
            else if(this.performanceCharttype == 'M'){
                if(Object.keys(this.store.trendingIsins).length > 0 && Object.keys(this.currencies_list).length){
                    Object.keys(this.currencies_list).forEach(val => {
                        if(val in this.store.trendingIsins && Object.keys(this.store.trendingIsins[val]).length && this.store.trendingIsins[val].monthly && typeof JSON.parse(this.store.trendingIsins[val].monthly) == 'object' &&  JSON.parse(this.store.trendingIsins[val].monthly).open){
                            let obj = {
                                value: parseFloat(parseFloat(parseFloat((parseFloat(this.currencies_list[val].B) - parseFloat(JSON.parse(this.store.trendingIsins[val].monthly).open))/parseFloat(JSON.parse(this.store.trendingIsins[val].monthly).open))*100).toFixed(2)),
                                currency: this.currencies_list[val].other.isinid,
                            }
                            data.push(obj);
                        }

                    })

                }
            }
            else if(this.performanceCharttype == 'Y'){
                if(Object.keys(this.store.trendingIsins).length > 0 && Object.keys(this.currencies_list).length){
                    Object.keys(this.currencies_list).forEach(val => {
                        if(val in this.store.trendingIsins && Object.keys(this.store.trendingIsins[val]).length && this.store.trendingIsins[val].yearly && typeof JSON.parse(this.store.trendingIsins[val].yearly) == 'object' &&  JSON.parse(this.store.trendingIsins[val].yearly).open){
                            let obj = {
                                value: parseFloat(parseFloat(parseFloat((parseFloat(this.currencies_list[val].B) - parseFloat(JSON.parse(this.store.trendingIsins[val].yearly).open))/parseFloat(JSON.parse(this.store.trendingIsins[val].yearly).open))*100).toFixed(2)),
                                currency: this.currencies_list[val].other.isinid,
                            }
                            data.push(obj);
                        }

                    })

                }
            }
            if(data.length){

                data.sort((a, b) => {
                    return a.value - b.value;
                });

                /*if (this.performanceChartFilter.currency == "Major") {
                    data = data.filter(
                        (i) => !this.static_vars.crossPairs.includes(i.currency)
                    );
                }*/ /*else if (this.performanceChartFilter.currency == "Minor") {
                    data = data.filter((i) =>
                        this.static_vars.crossPairs.includes(i.currency)
                    );
                }*/

                if (rangeValue) {
                    this.performanceChartFilter.range.value = [
                        rangeValue[0],
                        rangeValue[1],
                    ];
                } else {
                    this.performanceChartFilter.range.min = data.reduce(function (
                        prev,
                        curr
                    ) {
                        return prev < curr.value ? prev : curr.value;
                    });
                    this.performanceChartFilter.range.max = data.reduce(function (
                        prev,
                        curr
                    ) {
                        return prev > curr.value ? prev : curr.value;
                    });
                    this.performanceChartFilter.range.value = [
                        this.performanceChartFilter.range.min,
                        this.performanceChartFilter.range.max,
                    ];
                }
                 if (rangeValue) {
                    data = data.filter(
                        (i) =>
                            i.value >= this.performanceChartFilter.range.value[0] &&
                            i.value <= this.performanceChartFilter.range.value[1]
                    );
                 }
            }
            series.columns.template.adapters.add("stroke", (fill, target) => {
                if (target._dataItem.dataContext.value > 0) {
                    return "#16C784";
                } else {
                    return "#E88018";
                }
            });
            series.columns.template.adapters.add("fill", (fill, target) => {
                if (target._dataItem.dataContext.value > 0) {
                    return "#16C784";
                } else {
                    return "#E88018";
                }
            });
            
            series.bullets.push(function(root) {
              return am5.Bullet.new(root, {
                locationX: 1.1,
                locationY: 0.5,
                sprite: am5.Label.new(root, {
                  text: "{valueX}%",
                  centerX: am5.percent(50),
                  centerY: am5.percent(50),
                  fontSize : 10,
                  populateText: true
                })
              });
            });

            root.interfaceColors.set("grid", am5.color(0xffffff));
            yAxis.data.setAll(data);
            series.data.setAll(data);
            series.appear(1000);
            chart.appear(1000, 100);
        },
    },
    computed: {
        sortList() {
            let list = Object.values(this.currencies_list);
            if (this.sorting.Last == 0) {
                list.sort(function (a, b) {
                    return a.B - b.B;
                });
            } else if (this.sorting.Last == 1) {
                list.sort(function (a, b) {
                    return b.B - a.B;
                });
            }
            if (this.sorting.Open == 0) {
                list.sort(function (a, b) {
                    return a.O - b.O;
                });
            } else if (this.sorting.Open == 1) {
                list.sort(function (a, b) {
                    return b.O - a.O;
                });
            }
            if (this.sorting.High == 0) {
                list.sort(function (a, b) {
                    return a.H - b.H;
                });
            } else if (this.sorting.High == 1) {
                list.sort(function (a, b) {
                    return b.H - a.H;
                });
            }
            if (this.sorting.Low == 0) {
                list.sort(function (a, b) {
                    return a.L - b.L;
                });
            } else if (this.sorting.Low == 1) {
                list.sort(function (a, b) {
                    return b.L - a.L;
                });
            }
            if (this.sorting.Chg == 0) {
                list.sort(function (a, b) {
                    return parseFloat(a.B) - parseFloat(b.other.closepx);
                });
            } else if (this.sorting.Chg == 1) {
                list.sort(function (a, b) {
                    return parseFloat(b.other.closepx) - parseFloat(a.B);
                });
            }
            if (this.sorting.ChgP == 0) {
                list.sort(function (a, b) {
                    return a.CS - b.CS;
                });
            } else if (this.sorting.ChgP == 1) {
                list.sort(function (a, b) {
                    return b.CS - a.CS;
                });
            }
            return list;
        },
        overAllGainer() {
            try {
                if (
                    Object.keys(this.topGainer).length > 0 &&
                    Object.keys(this.topLoser).length > 0
                ) {
                    let gainer = Object.values(this.topGainer).slice(0, 2);
                    let loser = Object.values(this.topLoser).slice(0, 2);
                    return gainer.concat(loser);
                }
            } catch (ex) {
                return [];
            }
        },
        topGainer() {
            try {
                this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
                return this.$store.getters[Const.GET_PROVIDERS][
                    "priceFeed"
                ].iCache.getTopGainerNew();
            } catch (ex) {
                return {};
            }
        },
        topLoser() {
            try {
                this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
                return this.$store.getters[Const.GET_PROVIDERS][
                    "priceFeed"
                ].iCache.getTopLooserNew();
            } catch (ex) {
                return {};
            }
        },
        get_currencies() {
            let list = Object.values(this.currencies_list);
            if (list.length > 0) {
                var _that = this;
                let allList = [],
                    returnList = [];
                list = this.sortList;
                // if (this.tab == 1 || this.tab == 8) {
                //     if (this.tab == 1) {
                //         list = list.filter(
                //             (i) => !this.static_vars.crossPairs.includes(i.other.isinid)
                //         );
                //     } else {
                //         list = list.filter((i) =>
                //             this.static_vars.crossPairs.includes(i.other.isinid)
                //         );
                //     }
                // }
                allList = list.filter((val) => {
                    if (this.search == "") {
                        // for All case
                        return true;
                    } else {
                        //return val.i.toLowerCase().split('-').includes(_that.search.toLowerCase())
                        return (
                            val.other.isinid
                                .toLowerCase()
                                .includes(_that.search.trim().toLowerCase()) ||
                            val.other.Description.toLowerCase().includes(
                                _that.search.trim().toLowerCase()
                            )
                        );
                    }
                });
                returnList = allList.filter(
                    (val, index) =>
                        index < this.page * this.limit &&
                        index >= (this.page - 1) * this.limit
                );
                return { list: returnList, total: allList.length };
            } else {
                return { list: [], total: 0 };
            }
        },
        currencies_list() {
            try {
                this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
                return this.$store.getters[Const.GET_PROVIDERS]["priceFeed"].iCache[
                    this.priceType[this.tab]
                ]();
            } catch (ex) {
                return {};
            }
        },

        priceList() {
            return Object.values(this.get_currencies).slice(0, 8);
        },
    },
};
</script>
<style src="@vueform/slider/themes/default.css">

</style>

<style>
#performance-chart {
    height: 600px;
}
#performance-chart>div {
    display: none;
}

#performance-chart>div:last-child {
    display: block !important;
}
</style>
